import React from "react"

import Layout from "../components/layout"
import Layoutsection from "../components/layoutsection"
import Content from "../components/content"
import SEO from "../components/seo"
import Image from "../components/image"

import BackgroundImage from "gatsby-background-image"

import { StaticQuery, graphql } from "gatsby"

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        mainBG: file(relativePath: { eq: "sv_background.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        logo: file(relativePath: { eq: "ventures_logo.png" }) {
          childImageSharp {
            fixed(width: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <SEO title="Welcome" />
        <Layoutsection className="welcome">
          <BackgroundImage
            fluid={data.mainBG.childImageSharp.fluid}
            style={{
              backgroundPosition: "center",
              overflow: "visible",
              backgroundSize: "cover",
            }}
          >
            <Content>
              <div className="row" style={{ minHeight: "100vh" }}>
                <div style={{ maxWidth: "300px", textAlign: "center", margin: "0 auto", padding: "60px 0 30px" }}>
              <Image fixed={data.logo.childImageSharp.fixed} />
              </div>
              <div style={{ maxWidth: "980px", padding: "30px", color: "white", margin: "0 auto", fontSize: "1rem", lineHeight: "1.8rem" }}>
                <p>
                  Sagamore Ventures (formerly Plank Industries) is a privately-held investment company with diversified holdings that include commercial real estate, hospitality, food and beverage, and venture capital. The company is based in Baltimore, MD, and serves as the family office of Kevin A. Plank, the founder, Executive Chairman, and Brand Chief of Under Armour, Inc. Key investment holdings include Sagamore Spirit and a major equity stake in the Baltimore Peninsula redevelopment in South Baltimore.
                </p>
                <p>
                  The mission of Sagamore Ventures is to execute the initiatives of the Plank Family, support the growth of our investments, and contribute to economic development and opportunity in Baltimore City.
                </p>
                <p>
                <span style={{ fontSize: ".8rem" }}>© {new Date().getFullYear()} Sagamore Ventures</span>
                </p>
              </div>
              </div>
            </Content>
          </BackgroundImage>
        </Layoutsection>
      </Layout>
    )}
  />
)

export default IndexPage
